<template>
  <div class="material" v-if="item" :id="`${page}_${item.slug}`">
    <div class="material__image-container">
      <!-- <div
        class="material__play"
        v-if="video && !hideIcon"
      >
        <svg
          width="46"
          height="46"
          viewBox="0 0 46 46"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 23C0 10.2975 10.2975 0 23 0C35.7025 0 46 10.2975 46 23C46 35.7025 35.7025 46 23 46C10.2975 46 0 35.7025 0 23Z"
            fill="#EBEFEE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18 13L28 23L18 33"
            fill="#830051"
          />
        </svg>
      </div> -->
      <div class="material__tag_new" v-if="item.is_new">Новинка</div>
      <img
        class="material__image"
        v-if="item.announce && Object.keys(item.announce).length"
        :src="item.announce.url || item.announce"
        @click="goTo()"
      />
      <div class="material__category">
        <span v-if="item.type && Object.keys(item.type).length">
          {{ item.type.name }}
        </span>

        <Favorite
          v-if="!hideFavorites"
          class="ml-auto"
          :watchLater="item.watch_later"
          :favorite="item.favorite"
          @watchLater="$emit('watchLater')"
          @favorite="$emit('favorite')"
        />
      </div>
    </div>
    <div class="material__text-container" @click="goTo()">
      <div class="material__tags">
        <div
          class="material__tag"
          v-for="(nosology, i) in item.nosology"
          :key="`nos${i}`"
          v-html="nosology.title"
        >
         
        </div>
        <div
          class="material__tag"
          v-for="(disease, i) in item.disease"
          :key="`dis${i}`"
           v-html="disease.title"
        >
         
        </div>
      </div>
      <div class="material__title" v-html="item.name"></div>
      <div
        class="material__description"
        v-if="item.preview"
        v-html="item.preview"
      ></div>
    </div>
  </div>
</template>

<script>
import Favorite from "./Favorite.vue";
import { mapActions } from "vuex";
export default {
  data: () => ({
    count: 0,
  }),
  components: { Favorite },
  name: "Material",
  props: {
    item: Object,
    hideFavorites: {
      type: Boolean,
      default: false,
    },
    video: {
      type: Boolean,
      default: false,
    },
    page: String,
    hideIcon: Boolean,
  },
  computed: {
    link() {
      return { name: this.page, params: { slug: this.item.slug } };
    },
  },
  methods: {
    ...mapActions(['fetchTextHistory']),
    async goTo() {
      this.$emit("clickMaterials", this.item.name);
      if (this.item.file && !this.item.content) {
        window.open(this.item.file);
        await this.fetchTextHistory(this.item.slug);
       
      } else {
        if(this.item.link) {
          window.open(this.item.link, this.item._blank ? '_blank' : '_self');
        } else {
          this.$router.push(this.link).catch(() => {});
        }
        
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.material {
  background-color: #fff;
  cursor: pointer;
  border: 1px solid #d2d2d2;

  &:hover {
    .material__title {
      color: #830051;
    }
  }

  &__image-container {
    position: relative;
    width: 100%;
    height: 214px;
    @media screen and (max-width: 1220px) {
      height: 150px;
    }

    @media screen and (max-width: 767px) {
      height: 180px;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  &__category {
    padding: 8px 24px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: block;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    // color: #ffffff;
    // background: rgba(60, 66, 66, 0.6);
    color: #1f1f1f;
    background-color: rgba(235, 239, 238, 0.8);
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__text-container {
    padding: 16px 24px 24px;
    @media screen and (max-width: 1220px) {
      padding: 16px;
    }
  }

  &__tags {
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }

  &__tag {
    padding-left: 10px;
    position: relative;
    margin-right: 8px;
    margin-bottom: 8px;
    display: inline-block;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    color: #3c4242;
    white-space: nowrap;
    // max-width: calc(50% - 16px);
    text-overflow: ellipsis;
    overflow: hidden;

    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 2px;
      height: 2px;
      background-color: #3C4242;
      border-radius: 50%;
      display: block;
      content: "";
    }

    &:last-child {
      margin-right: 0;
    }

    &_new {
      position: absolute;
      top: 8px;
      left: 8px;
      z-index: 4;
      display: inline-block;
      padding: 4px 8px;
      background: rgba(255, 255, 255, 0.8);
      border: 1px solid #d8dada;
      border-radius: 4px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      color: #1f1f1f;
    }
  }

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #3c4242;
    transition: 0.3s;

    @media screen and (max-width: 1220px) {
      font-size: 16px;
      line-height: 20px;
    }

    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__description {
    margin-top: 8px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #656969;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    -webkit-box-orient: vertical;
    display: none;
  }
}
</style>